<template>
  <article ref="striping" class="tpl-dw-mag" :data-uuid="uuid">
    <div class="contain-override pb-12">
      <PageHeader :title="t('title')" />
      <LytMagazineHighlight :label="title" :body="body" :image="image">
        <template v-if="dailyMessage" #promotion>
          <div
            class="lyt-daily-message space-y-4 py-8 lg:py-0"
            :data-uuid="dailyMessage.uuid"
          >
            <h2 class="text-4xl font-light mb-0">
              {{ t(`Today's Word`, { label: dailyMessage.title }) }}
            </h2>
            <h3 class="text-2xl font-medium uppercase mt-4">
              {{ dailyMessage.subtitle }}
            </h3>
            <Transition name="fade">
              <AccessControlLoginLinks
                v-if="isDenied"
                :hide-subscribe-link-when-not-authenticated="true"
              />
              <LinkTo v-else class="button" :to="dailyMessage.path">
                {{ t('Read Message') }}
              </LinkTo>
            </Transition>
          </div>
        </template>
      </LytMagazineHighlight>
    </div>
    <section class="contain-override articles py-12">
      <LytGridFourUp :length="articles.length">
        <template #item="{ idx }">
          <template
            v-for="card in [getCardAt(articles, idx)]"
            :key="card?.uuid"
          >
            <Card v-if="card" :="card" />
          </template>
        </template>
      </LytGridFourUp>
    </section>
    <section class="contain-override info py-12 space-y-12">
      <SectionHeader :title="t('additional-info.title')">
        <template #body>
          <I18nT tag="p" class="summary my-2" keypath="additional-info.summary">
            <template #pubname>
              <span>{{ t('additional-info.pubname') }}</span>
            </template>
          </I18nT>
        </template>
      </SectionHeader>
      <LytGridFourUp :length="additionalInfo.length">
        <template #item="{ idx }">
          <template
            v-for="card in [getCardAt(additionalInfo, idx)]"
            :key="card?.uuid"
          >
            <Card v-if="card" :="card" />
          </template>
        </template>
      </LytGridFourUp>
    </section>
    <section class="contain-override info py-12 space-y-12">
      <SectionMagazinePastIssues
        :mag="locale === 'es' ? 'la-palabra-diaria' : 'daily-word'"
        :current-issue="uuid"
      />
    </section>
  </article>
</template>

<script lang="ts" setup>
import { getCardAt } from '~/utils/get-card'
import * as logger from '~/utils/logging'
import type { TeaserDailyMessage, MagazinePageInterface } from '~/types'
type MagazineProps = Omit<
  MagazinePageInterface,
  'articles' | 'additionalInfo' | 'author' | 'promotion'
>
const props = defineProps<MagazineProps>()

if (!props.uuid) {
  logger.error('Missing uuid on DailyWordMagazine component', { props })
}

const { t, locale } = useI18n()

const { isAllowed, isDenied } = usePermissions(PERMISSIONS.DAILY_WORD)

const { data } = useLazyFetch(`/api/data/daily-word/${props.uuid}/content`, {
  params: { lang: locale },
})

const { data: dailyMessage } = useLazyFetch<TeaserDailyMessage>(
  '/api/data/daily-message/today/',
  {
    params: { lang: locale, teaser: true },
  },
)

const promotion = computed(() => unref(data)?.promotion)
const articles = computed(() => unref(data)?.articles || [])
const additionalInfo = computed(() => unref(data)?.additionalInfo || [])

const striping = ref()
useStriping(striping)
</script>

<i18n lang="json">
{
  "en": {
    "title": "Daily Word Magazine",
    "Today's Word": "Today's Word: {label}",
    "Read Message": "Read Message",
    "additional-info": {
      "title": "Additional Info",
      "summary": "{pubname} published by Unity since 1924, offers inspiration and practical teachings through daily affirmation messages to help people of all faiths live healthy, prosperous, and meaningful lives.",
      "pubname": "Daily Word,"
    }
  },
  "es": {
    "title": "La Palabra Diaria",
    "Today's Word": "La palabra de hoy: {label}",
    "Read Message": "Leer mensaje",
    "additional-info": {
      "title": "Información adicional",
      "summary": "{pubname} ha sido publicada por Unity desde 1924, ofrece inspiración y enseñanzas prácticas mediante mensajes afirmativos diarios para ayudar a las personas de todo credo.",
      "pubname": "La Palabra Diaria"
    }
  }
}
</i18n>
